import Image from 'next/image'
import { styled, mq, css } from '@creditas/stylitas'
import { Typography } from '@creditas-lab/typography'
import { palette } from '@creditas-lab/style-utilities'
import { fillMediaBase } from 'ui/utils/fillMediaBase'
import { scalingIn, scalingOut } from 'ui/utils/animation'

const Wrapper = styled.div`
  cursor: pointer;
  width: 100%;
`

const ImageElement = styled(Image)`
  object-fit: cover;
  z-index: 2;
  animation: ${scalingOut} 0.3s ease;

  &:hover {
    animation: ${scalingIn} 0.3s ease;
    animation-fill-mode: forwards;
  }
`

const getImageWrapperVariation = {
  vertical: css`
    overflow: hidden;

    ${mq({
      marginBottom: fillMediaBase('16px', '24px'),
      height: fillMediaBase('0', '288px'),
      paddingBottom: fillMediaBase('100%', '0'),
    })}
  `,
  horizontal: mq({
    marginBottom: fillMediaBase(`16px`, '0'),
    height: 'auto',
  }),
}

const ImageWrapper = styled.div(
  ({ orientation }) => css`
    position: relative;

    ${getImageWrapperVariation[orientation]}
  `,
)

const ImageBackground = styled.div(
  ({ theme }) => css`
    width: calc(100% - 14px);
    height: calc(100% - 14px);
    position: absolute;
    background: ${palette.neutral10(theme)};
    top: 7px;
    left: 7px;
    filter: blur(4px);
    -webkit-filter: blur(4px);
    z-index: 1;
    opacity: 0.6;
  `,
)

const LabelCategory = styled(Typography)`
  text-transform: uppercase;
  margin-bottom: 8px;

  &:hover {
    text-decoration: underline;
  }
`

const LabelTitle = styled(Typography)`
  cursor: pointer;

  ${mq({
    marginBottom: fillMediaBase('8px', '16px'),
  })}

  &:hover {
    text-decoration: underline;
  }
`

const variantOptions = {
  vertical: {
    imageGrid: { xs: '1 / 13', md: '1 / 13' },
    contentGrid: { xs: '1 / 13', md: '1 / 13' },
    image: {
      layout: 'fill',
    },
  },
  horizontal: {
    imageGrid: { xs: '1 / 13', md: '1 / 5' },
    contentGrid: { xs: '1 / 13', md: '5 / 13' },
    image: {
      layout: 'responsive',
      width: 188,
      height: 188,
    },
  },
}

const gridOptions = {
  gridPadding: ['0'],
}

export {
  Wrapper,
  ImageElement,
  ImageWrapper,
  ImageBackground,
  LabelCategory,
  LabelTitle,
  variantOptions,
  gridOptions,
}
