import Head from 'next/head'
import { string, bool, oneOfType } from 'prop-types'
import generateLocalImageUrl from 'utils/generateLocalImageUrl'
import { Facebook } from './Facebook'
import { Twitter } from './Twitter'
const propTypes = {
  title: string.isRequired,
  path: string.isRequired,
  description: string,
  noindex: bool,
  ogImage: oneOfType([string, bool]),
}

const Metatags = ({ title, description, noindex, path, ogImage }) => {
  let url = `${process.env.BASE_URL}/`
  if (path !== '/') url += `${path.replace(/^\//, '')}/`

  const image = ogImage ? ogImage : generateLocalImageUrl('og-image.jpg')

  return (
    <>
      <Head>
        {title && (
          <>
            <title key="title">{title}</title>
            <meta name="description" key="description" content={description} />
            <link rel="canonical" key="canonical" href={url} />
          </>
        )}
        {(noindex || process.env.NO_INDEX === 'true') && (
          <meta name="robots" content="noindex" />
        )}
        <meta
          name="p:domain_verify"
          content="8fbe9b48d83805b9370ab0d0c2d6b698"
        />
      </Head>

      <Facebook
        title={title}
        description={description}
        url={url}
        siteName="RH Estratégico"
        image={image}
      />

      <Twitter
        title={title}
        description={description}
        url={url}
        siteName="RH Estratégico"
        image={image}
      />
    </>
  )
}
Metatags.propTypes = propTypes

export default Metatags
