import { styled, css } from '@creditas/stylitas'
import { palette } from '@creditas-lab/style-utilities'
import Clock from '@creditas/icons/dist/content/Clock'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`

const Icon = styled(Clock)(
  ({ theme }) => css`
    margin-top: -6px;
    margin-bottom: -6px;
    margin-left: -6px;
    margin-right: -3px;
    fill: ${palette.neutral60(theme)};
  `,
)

export { Wrapper, Icon }
