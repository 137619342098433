import { number, oneOfType, string } from 'prop-types'
import { Typography } from '@creditas-lab/typography'
import { Wrapper, Icon } from './style'

const propTypes = {
  minutes: oneOfType([string, number]),
  'data-testid': string,
}

const defaultProps = {
  minutes: 0,
}

const ReadingTime = ({ minutes, 'data-testid': dataTestid }) => (
  <Wrapper data-testid={dataTestid}>
    <Icon />
    <Typography desktop="sm" mobile="sm" color="neutral60">
      {`${minutes} minuto${minutes === 1 ? '' : 's'} de leitura`}
    </Typography>
  </Wrapper>
)

ReadingTime.propTypes = propTypes
ReadingTime.defaultProps = defaultProps

export default ReadingTime
