import { keyframes } from '@creditas/stylitas'

export const scalingIn = keyframes`
  0% {
      transform: scale(1.0);
  }
  100% {
      transform: scale(1.1);
  }
`

export const scalingOut = keyframes`
  0% {
      transform: scale(1.1);
  }
  100% {
      transform: scale(1.0);
  }
`
