import { number, string, bool, oneOf, object, oneOfType } from 'prop-types'
import { Grid, GridItem } from '@creditas/layout'
import Link from 'components/Link'
import ReadingTime from 'components/ReadingTime'
import generateLocalImageUrl from 'utils/generateLocalImageUrl'
import {
  Wrapper,
  ImageElement,
  ImageWrapper,
  ImageBackground,
  LabelCategory,
  LabelTitle,
  variantOptions,
  gridOptions,
} from './style'

const propTypes = {
  image: oneOfType([string, bool]),
  title: string,
  headingType: oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  slug: string,
  category: object,
  readingTime: oneOfType([string, number]),
  variant: oneOf(['horizontal', 'vertical']),
  position: oneOfType([string, number]),
  galleryTitle: string,
}

const defaultProps = {
  variant: 'vertical',
  headingType: 'h3',
}

const defaultImage = generateLocalImageUrl('image-default.jpg')

const PostCard = ({
  readingTime,
  image,
  title,
  headingType,
  slug,
  category,
  variant,
  position,
  galleryTitle,
  ...others
}) => {
  const options = variantOptions[variant]
  const cardPosition = parseInt(position, 10) + 1
  const gaCategory = galleryTitle
    ? `${galleryTitle} | ${cardPosition}`
    : cardPosition

  return (
    <Wrapper data-testid="post-card" {...others}>
      {slug && (
        <Grid data-testid="post-card-content" options={gridOptions}>
          <GridItem gridColumn={options.imageGrid}>
            <ImageWrapper
              data-testid="post-card-image-wrapper"
              orientation={variant}
            >
              <Link
                href={slug || ''}
                aria-label={`Ir para ${title}`}
                gaEvent={{
                  category: `post card`,
                  action: `post image | ${gaCategory}`,
                  label: slug,
                  internal: true,
                }}
              >
                <ImageElement
                  src={image || defaultImage}
                  alt={title}
                  data-testid={`post-card-image-${variant}`}
                  {...options.image}
                />
              </Link>
              <ImageBackground />
            </ImageWrapper>
          </GridItem>
          <GridItem gridColumn={options.contentGrid}>
            {category && (
              <Link
                href={category.slug || ''}
                aria-label={`Ir para categoria ${category.title}`}
                gaEvent={{
                  category: `post card`,
                  action: `post category | ${gaCategory}`,
                  label: category.slug,
                  internal: true,
                }}
              >
                <LabelCategory
                  color="brandPrimaryDefault"
                  desktop="sm"
                  mobile="xs"
                  weight="lg"
                  data-testid="post-card-category"
                  orientation={variant}
                >
                  {category.title}
                </LabelCategory>
              </Link>
            )}
            {title && (
              <Link
                href={slug || ''}
                aria-label={`Ir para ${title}`}
                gaEvent={{
                  category: `post card`,
                  action: `post title | ${gaCategory}`,
                  label: slug,
                  internal: true,
                }}
              >
                <LabelTitle
                  desktop="xl"
                  mobile="md"
                  orientation={variant}
                  as={headingType}
                  data-testid="post-card-link"
                >
                  {title}
                </LabelTitle>
              </Link>
            )}
            {readingTime && (
              <ReadingTime minutes={readingTime} data-testid="post-card-time" />
            )}
          </GridItem>
        </Grid>
      )}
    </Wrapper>
  )
}

PostCard.propTypes = propTypes
PostCard.defaultProps = defaultProps

export default PostCard
