import { string, oneOf, array } from 'prop-types'
import {
  PostCard,
  LabelTitle,
  Wrapper,
  ItemList,
  StyledContainer,
  getGridOptions,
} from './style'

const propTypes = {
  title: string,
  color: string,
  variant: oneOf(['carousel', 'stack']),
  headingType: oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  posts: array,
}

const defaultProps = {
  variant: 'carousel',
  headingType: 'h3',
}

const PostCardGallery = ({
  title,
  color,
  variant,
  headingType,
  posts,
  ...others
}) => {
  if (!posts.length) return null

  const totalCards = posts.length

  return (
    <Wrapper data-testid="post-card-gallery" color={color} {...others}>
      <StyledContainer maxWidth="lg" variant={variant}>
        {title && (
          <LabelTitle
            desktop="xl"
            mobile="lg"
            color="neutral90"
            as={headingType}
            galleryVariant={variant}
            data-testid="gallery-title"
          >
            {title}
          </LabelTitle>
        )}
        <ItemList
          variant={variant}
          options={getGridOptions(totalCards, variant)}
          totalCards={totalCards}
          data-testid={`gallery-list-${variant}`}
        >
          {posts?.map((post, key) => (
            <PostCard
              key={key}
              position={key}
              galleryVariant={variant}
              galleryTitle={title}
              {...post}
            />
          ))}
        </ItemList>
      </StyledContainer>
    </Wrapper>
  )
}

PostCardGallery.propTypes = propTypes
PostCardGallery.defaultProps = defaultProps

export default PostCardGallery
