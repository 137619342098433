import Head from 'next/head'
import { string, bool, oneOfType } from 'prop-types'

const propTypes = {
  title: string,
  description: string,
  siteName: string,
  url: string,
  image: oneOfType([string, bool]),
}

const Twitter = ({ title, description, siteName, url, image }) => (
  <Head>
    {title && <meta name="twitter:title" content={title} />}
    {description && <meta name="twitter:description" content={description} />}
    {url && <meta name="twitter:url" content={url} />}
    <meta name="twitter:card" content="summary" />
    {siteName && <meta name="twitter:creator" content={siteName} />}
    {image && <meta name="twitter:image" content={image} />}
  </Head>
)

Twitter.propTypes = propTypes

export { Twitter }
