import { styled, mq, css } from '@creditas/stylitas'
import { Container, Grid } from '@creditas/layout'
import { palette } from '@creditas-lab/style-utilities'
import { Typography } from '@creditas-lab/typography'
import { fillMediaBase, fillMediaTablet } from 'ui/utils/fillMediaBase'
import PostCardDefault from 'components/PostCard'

const Wrapper = styled.div(
  ({ theme, color }) => css`
    width: 100%;
    overflow: hidden;

    ${color && `background-color: ${palette[color](theme)}`};

    ${mq({
      padding: fillMediaBase('48px 0', '72px 0'),
    })}
  `,
)

const LabelTitle = styled(Typography)(
  ({ theme, galleryVariant }) => css`
    color: ${palette.neutral90(theme)};
    font-weight: 300;
    letter-spacing: 0.5px;
    font-weight: 300;

    ${mq({
      marginBottom: fillMediaBase('24px', '40px'),
      fontSize: fillMediaBase('28px', '40px'),
      lineHeight: fillMediaBase('36px', '56px'),
    })}

    ${galleryVariant === 'carousel' &&
    css`
      ${mq({
        paddingLeft: fillMediaBase('16px', '0px'),
      })}
    `}
  `,
)

const itemListVariation = totalCards => ({
  stack: css`
    ${mq({
      gridTemplateColumns: fillMediaTablet(
        null,
        `repeat(2, minmax(0, 1fr))`,
        `repeat(${totalCards}, minmax(0, 1fr))`,
      ),
    })}
  `,
  carousel: css`
    grid-auto-flow: column;
    overflow: auto;
    ::-webkit-scrollbar {
      display: none;
    }
    ${mq({
      margin: fillMediaTablet(0, 0, null),
      gridTemplateColumns: fillMediaTablet(
        'unset',
        'unset',
        `repeat(${totalCards}, minmax(0, 1fr))`,
      ),
    })}
  `,
})

const ItemList = styled(Grid)(
  ({ variant, totalCards }) => css`
    width: 100%;
    box-sizing: content-box;

    ${itemListVariation(totalCards)[variant || 'stack']}

    ${mq({
      gridAutoColumns: fillMediaTablet(
        `${totalCards === 1 ? `100%` : `236px`}`,
        `calc(100% / 3 - (24px))`,
        `calc(100% / ${totalCards === 3 ? 3 : 4} - (24px))`,
      ),
    })}
  `,
)

const STYLED_CONTAINER_VARIATION = {
  stack: null,
  carousel: mq({
    padding: fillMediaBase('0px', '0 16px'),
  }),
}

const StyledContainer = styled(Container)(
  ({ variant }) => css`
    ${STYLED_CONTAINER_VARIATION[variant || 'stack']}
  `,
)

const POST_CARD_VARIATION = {
  stack: null,
  carousel: css`
    ${mq({
      paddingLeft: fillMediaTablet(16, 16, 0),
      '&:last-of-type': {
        paddingRight: fillMediaTablet(16, 16, 0),
      },
    })}
  `,
}

const PostCard = styled(PostCardDefault)(
  ({ galleryVariant }) => css`
    width: 100%;
    ${POST_CARD_VARIATION[galleryVariant || 'stack']}
  `,
)

const getGridOptions = (totalCards, variant) => ({
  gridColumns: {
    xs: variant === 'stack' ? `repeat(1, minmax(0, 1fr))` : 'unset',
    md:
      variant === 'stack'
        ? `repeat(${totalCards === 3 ? 3 : 4}, minmax(0, 1fr))`
        : 'repeat(4, 1fr)',
  },
  gridPadding: ['0'],
  gridRowGap: {
    xs: '48px',
    md: '64px',
  },
  gridColumnGap: {
    xs: '16px',
    md: '24px',
  },
})

export {
  Wrapper,
  ItemList,
  LabelTitle,
  StyledContainer,
  getGridOptions,
  PostCard,
}
