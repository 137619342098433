import React from 'react'
import Head from 'next/head'
import { string, oneOfType, bool } from 'prop-types'

const propTypes = {
  title: string,
  description: string,
  siteName: string,
  url: string,
  image: oneOfType([string, bool]),
}

const Facebook = ({ title, description, siteName, url, image }) => (
  <Head>
    {title && <meta name="og:title" content={title} />}
    {description && <meta name="og:description" content={description} />}
    <meta name="og:type" content="website" />
    {siteName && <meta name="og:site_name" content={siteName} />}
    {url && <meta name="og:url" content={url} />}
    <meta name="og:locale" content="pt_BR" />
    {image && <meta name="og:image" content={image} />}
    <meta name="og:image:type" content="image/png" />
    <meta name="og:image:width" content="1200" />
    <meta name="og:image:height" content="628" />
  </Head>
)

Facebook.propTypes = propTypes

export { Facebook }
